import React from 'react';

const SeamlessTransitionIcon = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
      <rect width="100" height="100" rx="50" fill="#EDF4FF" />
      <path
        d="M70.8333 38.1458H50C49.1458 38.1458 48.4375 37.4374 48.4375 36.5833C48.4375 35.7291 49.1458 35.0208 50 35.0208H70.8333C71.6875 35.0208 72.3958 35.7291 72.3958 36.5833C72.3958 37.4583 71.6875 38.1458 70.8333 38.1458Z"
        fill="#2857D1"
      />
      <path
        d="M66.2078 48.4376H54.6454C50.0829 48.4376 48.4579 46.8334 48.4579 42.2917V33.7501C48.4579 29.2084 50.0829 27.6042 54.6454 27.6042H66.2078C70.7703 27.6042 72.3953 29.2084 72.3953 33.7501V42.3126C72.3953 46.8334 70.7703 48.4376 66.2078 48.4376ZM54.6245 30.7292C51.7912 30.7292 51.562 30.9584 51.562 33.7501V42.3126C51.562 45.1043 51.7912 45.3334 54.6245 45.3334H66.187C69.0203 45.3334 69.2495 45.1043 69.2495 42.3126V33.7501C69.2495 30.9584 69.0203 30.7292 66.187 30.7292H54.6245Z"
        fill="#2857D1"
      />
      <path
        d="M50 62.1042H29.1667C28.3125 62.1042 27.6042 61.3959 27.6042 60.5417C27.6042 59.6876 28.3125 58.9792 29.1667 58.9792H50C50.8542 58.9792 51.5625 59.6876 51.5625 60.5417C51.5625 61.4167 50.8542 62.1042 50 62.1042Z"
        fill="#2857D1"
      />
      <path
        d="M45.3745 72.3958H33.812C29.2495 72.3958 27.6245 70.7917 27.6245 66.25V57.7083C27.6245 53.1667 29.2495 51.5625 33.812 51.5625H45.3745C49.937 51.5625 51.562 53.1667 51.562 57.7083V66.2708C51.562 70.7917 49.937 72.3958 45.3745 72.3958ZM33.7912 54.6875C30.9578 54.6875 30.7287 54.9167 30.7287 57.7083V66.2708C30.7287 69.0625 30.9578 69.2917 33.7912 69.2917H45.3536C48.187 69.2917 48.4161 69.0625 48.4161 66.2708V57.7083C48.4161 54.9167 48.187 54.6875 45.3536 54.6875H33.7912Z"
        fill="#2857D1"
      />
      <path
        d="M56.2504 72.3959C55.6879 72.3959 55.1671 72.0834 54.8963 71.6042C54.6254 71.1042 54.6254 70.5209 54.9171 70.0209L57.1046 66.3751C57.5421 65.6459 58.5004 65.3959 59.2504 65.8334C60.0004 66.2709 60.2296 67.2292 59.7921 67.9792L59.2296 68.9167C64.9796 67.5626 69.2921 62.3959 69.2921 56.2292C69.2921 55.3751 70.0004 54.6667 70.8546 54.6667C71.7088 54.6667 72.4171 55.3751 72.4171 56.2292C72.3963 65.1459 65.1462 72.3959 56.2504 72.3959Z"
        fill="#2857D1"
      />
      <path
        d="M29.1667 45.3126C28.3125 45.3126 27.6042 44.6042 27.6042 43.7501C27.6042 34.8542 34.8542 27.6042 43.75 27.6042C44.3125 27.6042 44.8333 27.9168 45.1041 28.3959C45.375 28.8959 45.375 29.4792 45.0834 29.9792L42.8958 33.6251C42.4583 34.3542 41.5 34.6043 40.75 34.1668C40 33.7293 39.7708 32.7709 40.2083 32.0209L40.7708 31.0834C35.0208 32.4376 30.7083 37.6042 30.7083 43.7709C30.7291 44.6042 30.0208 45.3126 29.1667 45.3126Z"
        fill="#2857D1"
      />
    </svg>
  );
};

export default SeamlessTransitionIcon;
