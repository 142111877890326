import React from 'react';

import styled from '@emotion/styled';

import TextCard from '../../../components/TextCard/TextCard';

const WhoWeAre = () => {
  return (
    <Container>
      <StyledImg alt="about us" src="/static/img/about-us-hero.png" />
      <TextContainer>
        <TextCard title="Who we are" className="whiteText">
          With our user-friendly platform, finding the perfect locum assignment
          has never been easier. Customize your search based on specialty,
          location, and availability, and browse through a wide range of
          opportunities tailored to your preferences. Say goodbye to the hassle
          of traditional job hunting and embrace a seamless and efficient
          process.
          <br />
          <br /> We understand the importance of work-life balance and
          flexibility. Whether you're seeking short-term assignments, part-time
          options, or longer-term opportunities, we have you covered. Enjoy the
          freedom to create a schedule that suits your needs while gaining
          invaluable experience across various healthcare settings.
          <br />
          <br /> At Locumspace.co, your success is our priority. Our dedicated
          team provides exceptional support, guiding you through every step of
          your locum journey. From personalized assistance to timely
          communication, we're here to ensure your locum experience exceeds your
          expectations.
        </TextCard>
      </TextContainer>
    </Container>
  );
};

export default WhoWeAre;

// Styles

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 1fr);
  /* border: 2px solid yellow; */
  margin: 0 6.5rem 3.38rem;

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1.5rem;
    padding-top: 2rem;
  }
`;

const StyledImg = styled.img`
  /* border: 2px solid red; */
  grid-column: 1/11;
  grid-row: 1/4;
`;

const TextContainer = styled.div`
  padding: 2.69rem 3.31rem;
  background-color: ${({ theme }) => theme.palette.mainBlue};
  grid-column: 2/10;
  grid-row: 3/5;
  border-radius: 1rem;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    border-radius: 4px;
    margin-top: -2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1rem;
  }
`;
