import React from 'react';

const AfflilateHr = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
      <rect width="100" height="100" rx="50" fill="#EDF4FF" />
      <path
        d="M49.5 49.5C52.235 49.5 54.8581 48.4925 56.792 46.6992C58.726 44.9059 59.8125 42.4736 59.8125 39.9375C59.8125 37.4014 58.726 34.9691 56.792 33.1758C54.8581 31.3825 52.235 30.375 49.5 30.375C46.765 30.375 44.1419 31.3825 42.208 33.1758C40.274 34.9691 39.1875 37.4014 39.1875 39.9375C39.1875 42.4736 40.274 44.9059 42.208 46.6992C44.1419 48.4925 46.765 49.5 49.5 49.5ZM56.375 39.9375C56.375 41.6283 55.6507 43.2498 54.3614 44.4453C53.072 45.6409 51.3234 46.3125 49.5 46.3125C47.6766 46.3125 45.928 45.6409 44.6386 44.4453C43.3493 43.2498 42.625 41.6283 42.625 39.9375C42.625 38.2467 43.3493 36.6252 44.6386 35.4297C45.928 34.2341 47.6766 33.5625 49.5 33.5625C51.3234 33.5625 53.072 34.2341 54.3614 35.4297C55.6507 36.6252 56.375 38.2467 56.375 39.9375ZM70.125 65.4375C70.125 68.625 66.6875 68.625 66.6875 68.625H32.3125C32.3125 68.625 28.875 68.625 28.875 65.4375C28.875 62.25 32.3125 52.6875 49.5 52.6875C66.6875 52.6875 70.125 62.25 70.125 65.4375ZM66.6875 65.4248C66.6841 64.6406 66.1581 62.2819 63.8275 60.1207C61.5863 58.0425 57.3684 55.875 49.5 55.875C41.6281 55.875 37.4137 58.0425 35.1725 60.1207C32.8419 62.2819 32.3194 64.6406 32.3125 65.4248H66.6875Z"
        fill="#2857D1"
      />
    </svg>
  );
};

export default AfflilateHr;
