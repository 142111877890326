import React from 'react';

import styled from '@emotion/styled';

import Card from '../../../components/Card/Card';
import QualitySupportIcon from '../../../components/Icons/QualitySupportIcon';
import SeamlessTransitionIcon from '../../../components/Icons/SeamlessTransitionIcon';
import ServiceSecurity from '../../../components/Icons/ServiceSecurity';
import WwwIcon from '../../../components/Icons/WwwIcon';
import TitledSection from '../../../components/TitledSection/TitledSection';

const BenefitsOfLocum = () => {
  return (
    <TitledSection
      title="Benefits of working as a Locum"
      className="largeMargin"
    >
      <CardArea>
        <Card
          icon={<ServiceSecurity />}
          title="Stay in control"
          description="You’re free to work to your own availability. Our platform makes it easy to plan ahead."
        />
        <Card
          icon={<QualitySupportIcon />}
          title="Find the perfect shifts "
          description="Our intuitive technology will notify you of vacant shifts that match your skillset."
        />
        <Card
          icon={<SeamlessTransitionIcon />}
          title="Manage your availability"
          description="Our built-in calendar feature allows you to stay on top of what’s happening in your work-life."
        />
        <Card
          icon={<WwwIcon />}
          title="Earn on the go"
          description="You can work with a range of healthcare organisations and make as much as you want."
        />
        <Card
          icon={<WwwIcon />}
          title="Cut out Admin"
          description="Forget phone calls and paper trails, negotiate shift rates and talk to organisations through our platform."
        />
        <Card
          icon={<WwwIcon />}
          title="Added Perks"
          description="Join the Locuumspace community and access a range of exclusive discounts, online resources and more."
        />
      </CardArea>
    </TitledSection>
  );
};

export default BenefitsOfLocum;

// Styled
const CardArea = styled.section`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
  }
`;
