import React from 'react';

const ServiceSecurity = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
      <rect width="100" height="100" rx="50" fill="#EDF4FF" />
      <path
        d="M46.9995 72.3125C44.7495 72.3125 42.4787 71.6667 40.7287 70.3542L31.812 63.6875C29.4579 61.9375 27.6245 58.25 27.6245 55.2917V39.8542C27.6245 36.6667 29.9579 33.2708 32.9579 32.1458L43.3329 28.25C45.3954 27.4792 48.6245 27.4792 50.687 28.25L61.0412 32.1458C63.4787 33.0625 65.5412 35.5 66.2079 38.2083C66.312 38.6667 66.2079 39.1667 65.9162 39.5417C65.6245 39.9167 65.1662 40.1458 64.687 40.1458L48.2079 40.1667C45.562 40.3333 44.9162 40.7709 44.9162 44.625V55.9375C44.9162 59.9167 45.437 60.4167 49.4787 60.4167H61.812C62.4579 60.4167 63.0412 60.8125 63.2703 61.4167C63.4995 62.0209 63.3329 62.7083 62.8537 63.1458C62.6245 63.3333 62.437 63.5209 62.2079 63.6875L53.2912 70.375C51.4995 71.6667 49.2495 72.3125 46.9995 72.3125ZM46.9995 30.8125C46.062 30.8125 45.1037 30.9375 44.4162 31.2084L34.0412 35.1042C32.2912 35.7709 30.7287 38 30.7287 39.8958V55.3333C30.7287 57.2917 32.1037 60.0417 33.6662 61.2084L42.5829 67.875C44.9787 69.6458 48.9995 69.6458 51.3954 67.875L57.1662 63.5625H49.4579C43.7079 63.5625 41.7704 61.6458 41.7704 55.9583V44.6458C41.7704 40.2083 42.8329 37.3959 48.1037 37.0625L49.4579 37.0417H62.2704C61.6454 36.1458 60.812 35.4375 59.937 35.1042L49.5829 31.2084C48.8745 30.9375 47.937 30.8125 46.9995 30.8125Z"
        fill="#2857D1"
      />
      <path
        d="M70.8329 49.9375H43.3329C42.4788 49.9375 41.7704 49.2292 41.7704 48.375C41.7704 47.5208 42.4788 46.8125 43.3329 46.8125H70.8329C71.6871 46.8125 72.3954 47.5208 72.3954 48.375C72.3954 49.2292 71.6871 49.9375 70.8329 49.9375Z"
        fill="#2857D1"
      />
      <path
        d="M64.7079 63.5416H49.4579C43.7079 63.5416 41.7704 61.625 41.7704 55.9375V44.625C41.7704 40.1875 42.8329 37.3749 48.1037 37.0416L49.4579 37.0208H64.7079C70.4579 37.0208 72.3954 38.9375 72.3954 44.625V56.1874C72.3538 61.6874 70.4162 63.5416 64.7079 63.5416ZM49.4579 40.1458L48.1871 40.1666C45.5413 40.3333 44.8954 40.7708 44.8954 44.625V55.9375C44.8954 59.9166 45.4163 60.4166 49.4579 60.4166H64.7079C68.6871 60.4166 69.2288 59.8958 69.2704 56.1874V44.6458C69.2704 40.6666 68.7496 40.1666 64.7079 40.1666H49.4579V40.1458Z"
        fill="#2857D1"
      />
      <path
        d="M51.3543 58.3542H48.5626C47.7085 58.3542 47.0001 57.6459 47.0001 56.7917C47.0001 55.9376 47.7085 55.2292 48.5626 55.2292H51.3543C52.2084 55.2292 52.9168 55.9376 52.9168 56.7917C52.9168 57.6459 52.2293 58.3542 51.3543 58.3542Z"
        fill="#2857D1"
      />
      <path
        d="M62.5417 58.3542H55.7292C54.875 58.3542 54.1667 57.6459 54.1667 56.7917C54.1667 55.9376 54.875 55.2292 55.7292 55.2292H62.5417C63.3958 55.2292 64.1042 55.9376 64.1042 56.7917C64.1042 57.6459 63.4167 58.3542 62.5417 58.3542Z"
        fill="#2857D1"
      />
    </svg>
  );
};

export default ServiceSecurity;
