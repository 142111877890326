import React from 'react';

const QualitySupportIcon = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
      <rect width="100" height="100" rx="50" fill="#EDF4FF" />
      <path
        d="M50 72.3958C48.5417 72.3958 47.0625 71.8332 45.9583 70.729L42.3958 67.2083C41.5 66.3333 40.3333 65.8541 39.0833 65.8541H37.5C33.1875 65.8541 29.6875 62.375 29.6875 58.1042V35.3749C29.6875 31.1041 33.1875 27.625 37.5 27.625H62.5C66.8125 27.625 70.3125 31.1041 70.3125 35.3749V58.1042C70.3125 62.375 66.8125 65.8541 62.5 65.8541H60.9167C59.6667 65.8541 58.4792 66.3333 57.6042 67.2083L54.0417 70.729C52.9375 71.8332 51.4583 72.3958 50 72.3958ZM37.5 30.7291C34.9167 30.7291 32.8125 32.8124 32.8125 35.3541V58.0833C32.8125 60.6458 34.9167 62.7083 37.5 62.7083H39.0833C41.1667 62.7083 43.125 63.5207 44.6042 64.979L48.1667 68.5C49.1875 69.5 50.8333 69.5 51.8542 68.5L55.4167 64.979C56.8958 63.5207 58.8542 62.7083 60.9375 62.7083H62.5C65.0833 62.7083 67.1875 60.625 67.1875 58.0833V35.3541C67.1875 32.7916 65.0833 30.7291 62.5 30.7291H37.5Z"
        fill="#2857D1"
      />
      <path
        d="M46.6255 55.2291H41.0421C40.1255 55.2291 39.2713 54.7915 38.7296 54.0415C38.2088 53.3331 38.0838 52.4583 38.3338 51.625C39.063 49.3958 40.8546 48.1874 42.438 47.1041C44.1046 45.9791 45.0421 45.2708 45.0421 44.0625C45.0421 42.9791 44.1671 42.104 43.0838 42.104C42.0005 42.104 41.1255 42.9791 41.1255 44.0625C41.1255 44.9166 40.4172 45.625 39.563 45.625C38.7088 45.625 38.0005 44.9166 38.0005 44.0625C38.0005 41.2708 40.2713 38.979 43.0838 38.979C45.8963 38.979 48.1671 41.25 48.1671 44.0625C48.1671 47 45.9588 48.4999 44.188 49.7082C43.0838 50.4582 42.0421 51.1666 41.5213 52.1041H46.6046C47.4588 52.1041 48.1671 52.8124 48.1671 53.6666C48.1671 54.5208 47.4797 55.2291 46.6255 55.2291Z"
        fill="#2857D1"
      />
      <path
        d="M58.4164 55.2294C57.5622 55.2294 56.8539 54.521 56.8539 53.6669V52.2292H52.7706C52.7706 52.2292 52.7706 52.2292 52.7498 52.2292C51.7289 52.2292 50.7914 51.6877 50.2706 50.8127C49.7498 49.9169 49.7498 48.8127 50.2706 47.9377C51.6873 45.5002 53.3331 42.7293 54.8331 40.3126C55.4998 39.2501 56.7706 38.7711 57.9581 39.1044C59.1456 39.4586 59.9789 40.542 59.9581 41.792V49.1251H60.4164C61.2706 49.1251 61.9789 49.8334 61.9789 50.6876C61.9789 51.5418 61.2706 52.2501 60.4164 52.2501H59.9789V53.6877C59.9789 54.5419 59.2914 55.2294 58.4164 55.2294ZM56.8539 43.0002C55.6247 45.0002 54.3539 47.1459 53.2081 49.1042H56.8539V43.0002Z"
        fill="#2857D1"
      />
    </svg>
  );
};

export default QualitySupportIcon;
