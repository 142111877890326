import React from 'react';

import styled from '@emotion/styled';

import ColoredCard from '../../../components/Card/ColoredCard';
import Img from '../../../components/Img/Img';
import TitledSection from '../../../components/TitledSection/TitledSection';

const HowItWorks = () => {
  return (
    <TitledSection title="How It Works">
      <ContentArea>
        <Img src="/static/img/howitworks-doctor.png" />
        <CardArea>
          <ColoredCard title="Register" className="color11">
            Create a profile in under a minute. You’re in charge of your
            schedule, free to work whenever and wherever you want. We've
            designed our platform to fit in with your lifestyle so you can find
            the perfect shift for you. Sign-up now and get started.
          </ColoredCard>
          <ColoredCard title="Get Verified" className="color12">
            Our compliance team are on hand to assist you 24/7. Whether you want
            to know about what essential documents you'll need or you just want
            to give your profile an extra polish with some accreditations, our
            compliance team are on hand to help. Simply send us a message on
            chat and we'll get back to you.
          </ColoredCard>
          <ColoredCard title="Find Shifts" className="color21">
            Find & Apply for flexible, high paying shifts that suit your
            skillset via our desktop site or intuitive app. Filter shifts by
            location, date or rate. You choose where and when you work. Apply
            for as many shifts as you like, our technology means you'll never be
            double booked.
          </ColoredCard>
          <ColoredCard title="Earn" className="color22">
            Get Booked in under a minute and start earning great money. You will
            get instant notificaions of won shifts and these will sync
            automatically to your built in schedule allowing you to quickly see
            when you're working and how much you've earned.
          </ColoredCard>
        </CardArea>
      </ContentArea>
    </TitledSection>
  );
};

export default HowItWorks;

// Styled
const ContentArea = styled.section`
  display: flex;
  gap: 2.88rem;
  /* border: 2px solid red; */

  @media (max-width: 480px) {
    gap: 1rem;
    flex-direction: column;
  }
`;

const CardArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;

  @media (max-width: 480px) {
    gap: 1rem;
  }
`;
