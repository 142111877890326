import React from 'react';

import styled from '@emotion/styled';

import TextCard from '../../../components/TextCard/TextCard';
import { missionConfig } from './missionConfig';

const Mission = () => {
  return (
    <Container>
      <MissionContainer>
        {missionConfig.map((item, index) => (
          <TextCard key={index} title={item.title}>
            {item.description}
          </TextCard>
        ))}
      </MissionContainer>
      <ImgArea>
        <Img1 src="/static/img/wine-nurse.png" />
        <Img1 src="/static/img/blue-nurse.png" />
        <Img2 src="/static/img/green-nurse.png" />
      </ImgArea>
    </Container>
  );
};

export default Mission;

// Styled
const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 5.81rem 7.12rem;
  /* border: 1px solid red; */

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1.5rem;
  }
`;

const MissionContainer = styled.div`
  /* grid-column: ; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ImgArea = styled.div`
  height: fit-content;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

const Img1 = styled.img`
  /*  */
`;

const Img2 = styled.img`
  grid-column: 2/3;
`;
