import React from 'react';

import styled from '@emotion/styled';

import { SecondaryButton } from '../Button';
// import Img from '../Img/Img';
import TextCard from '../TextCard/TextCard';

interface IHero {
  title: string;
  description: string;
  imgSrc: string;
}

const Hero: React.FC<IHero> = ({ title, description, imgSrc }) => {
  return (
    <Container>
      <ImgContainer>
        <Img src={imgSrc} />
      </ImgContainer>

      <ContentArea>
        <TextCard title={title} className="whiteText">
          {description}
        </TextCard>
        <SecondaryButton className="whiteBtn">Get Started</SecondaryButton>
      </ContentArea>
    </Container>
  );
};

export default Hero;

// Styled

const Container = styled.div`
  display: grid;
  /* border: 2px solid red; */

  & > * {
    grid-column: 1;
    grid-row: 1;
  }
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 30rem;
  gap: 2rem;
  margin-left: 11.56rem;

  @media (max-width: 480px) {
    margin: 0;
    padding: 1.5rem;
    width: 100%;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  background-color: black;
`;

const Img = styled.img`
  @media (max-width: 480px) {
    object-fit: cover;
  }
`;
