import React from 'react';

import styled from '@emotion/styled';

import { PrimaryButton } from '../../../components/Button';
import Img from '../../../components/Img/Img';
import { ButtonLink } from '../../../components/Link/Link';
import TextCard from '../../../components/TextCard/TextCard';

const Advantages = () => {
  return (
    <Container>
      <Img src="/static/img/microscope-doctor.png" />
      <ContentArea>
        <TextCard title="Unlocking Opportunities: The Advantages of Working as a Locum ">
          In our latest blog post, we explore the world of locum doctors and the
          unique benefits and opportunities this career path offers. Discover
          the freedom, flexibility, and professional growth that comes with
          embracing the locum lifestyle. Whether you're seeking new experiences,
          a better work-life balance, or the chance to make a positive impact on
          diverse patient populations, locum work opens doors to endless
          possibilities. Join us as we delve into the exciting world of locum
          doctors and uncover the reasons why this path may be the perfect fit
          for your medical career.
        </TextCard>
        <ButtonLink to="/">
          <PrimaryButton arrowIcon className="big fullWidth">
            Read More
          </PrimaryButton>
        </ButtonLink>
      </ContentArea>
    </Container>
  );
};

export default Advantages;

// styled
const Container = styled.section`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  gap: 2.25rem;
  margin: 8.19rem 8rem;
  align-items: center;
  /* border: 2px solid red; */

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1.5rem;
  }
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
