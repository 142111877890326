import React from 'react';

const LeftArrowButtonIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path
        opacity="0.4"
        d="M23.8068 0.333008L10.1935 0.333008C4.29517 0.333008 0.3335 4.12634 0.3335 9.77467L0.3335 24.2247C0.3335 29.873 4.29517 33.6663 10.1935 33.6663L23.8068 33.6663C29.7035 33.6663 33.6668 29.8713 33.6668 24.223L33.6668 9.77467C33.6668 4.12634 29.7035 0.333008 23.8068 0.333008Z"
        fill="#2857D1"
      />
      <path
        d="M15.5743 9.86738L9.29933 16.114C8.82767 16.584 8.82767 17.4157 9.29933 17.8874L15.5743 24.134C16.0643 24.6207 16.856 24.619 17.3427 24.129C17.8293 23.639 17.8293 22.849 17.3393 22.3607L13.211 18.249H23.8027C24.4943 18.249 25.0527 17.689 25.0527 16.999C25.0527 16.309 24.4943 15.749 23.8027 15.749L13.211 15.749L17.3393 11.639C17.5843 11.394 17.706 11.074 17.706 10.7524C17.706 10.434 17.5843 10.114 17.3427 9.87071C16.856 9.38238 16.0643 9.38071 15.5743 9.86738Z"
        fill="#2857D1"
      />
    </svg>
  );
};

export default LeftArrowButtonIcon;
