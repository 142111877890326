import React from 'react';

import styled from '@emotion/styled';

import AppDownloadBtn from '../../../components/Button/AppDownloadBtn';

const GetOurApp = () => {
  return (
    <Container id="get-app">
      <TextSection>
        <Title>Life is a lot more easier with our app</Title>
        <Description>
          Our App is built to make it easier to list and find more jobs, quick
          access to more in-app features that empowers your practice like tools
          to organise your daily work seamlessly.
        </Description>
        <IconArea>
          <AppDownloadBtn link="http://google.com" platform="PlayStore" />
          <AppDownloadBtn link="http://google.com" platform="AppStore" />
        </IconArea>
      </TextSection>
      <ImageArea></ImageArea>
    </Container>
  );
};

export default GetOurApp;

const Container = styled.section`
  display: grid;
  grid-template-columns: 5fr 6fr;
  background-color: ${({ theme }) => theme.palette.mainBlue};

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 10rem 6rem 12rem 6rem;

  @media (max-width: 480px) {
    padding: 4rem 2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 400;
  color: white;

  @media (max-width: 480px) {
    font-size: 1.25rem;
    font-weight: 400;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  font-weight: 300;
  color: white;

  @media (max-width: 480px) {
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
  }
`;

const IconArea = styled.div`
  display: flex;
  gap: 2rem;
`;

const ImageArea = styled.div`
  display: flex;
  padding-right: 6rem;
  margin-right: 2rem;

  background: url('/static/img/leftPhone.png') no-repeat,
    url('/static/img/rightPhone.png') no-repeat bottom right;

  @media (max-width: 480px) {
    display: none;
  }
`;
