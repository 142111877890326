import React from "react";
import styled from "@emotion/styled";
import { PRIVACY_POLICY } from "../../routes/routeConstants";

export const Terms = () => {
  return (
    <Container>
      <h2>Terms and Conditions</h2>
      <p>
        <strong>Updated at:</strong> September 13th, 2024
      </p>

      <h2>General Terms</h2>
      <p>
        By accessing and placing an order with Locumspace, you confirm that you
        are in agreement with and bound by the terms of service contained in the
        Terms & Conditions outlined below. These terms apply to the entire
        website and any email or other type of communication between you and
        Locumspace.
      </p>
      <p>
        Under no circumstances shall the Locumspace team be liable for any
        direct, indirect, special, incidental, or consequential damages,
        including, but not limited to, loss of data or profit, arising out of
        the use or the inability to use the materials on this site, even if the
        Locumspace team or an authorized representative has been advised of the
        possibility of such damages. If your use of materials from this site
        results in the need for servicing, repair, or correction of equipment or
        data, you assume any costs thereof.
      </p>
      <p>
        Locumspace will not be responsible for any outcome that may occur during
        the course of usage of our resources. We reserve the right to change
        prices and revise the resources usage policy at any moment.
      </p>

      <h2>License</h2>
      <p>
        Locumspace grants you a revocable, non-exclusive, non-transferable,
        limited license to download, install, and use the website strictly in
        accordance with the terms of this Agreement.
      </p>
      <p>
        These Terms & Conditions are a contract between you and Locumspace
        (referred to in these Terms & Conditions as "Locumspace," "us," "we," or
        "our"), the provider of the Locumspace website and the services
        accessible from the Locumspace website (which are collectively referred
        to in these Terms & Conditions as the "Locumspace Service").
      </p>
      <p>
        You are agreeing to be bound by these Terms & Conditions. If you do not
        agree to these Terms & Conditions, please do not use the Locumspace
        Service. In these Terms & Conditions, "you" refers both to you as an
        individual and to the entity you represent. If you violate any of these
        Terms & Conditions, we reserve the right to cancel your account or block
        access to your account without notice.
      </p>

      <h2>Definitions and Key Terms</h2>
      <p>
        To help explain things as clearly as possible in this Terms &
        Conditions, every time any of these terms are referenced, they are
        strictly defined as:
      </p>
      <ul>
        <li>
          <strong>Cookie:</strong> small amount of data generated by a website
          and saved by your web browser. It is used to identify your browser,
          provide analytics, and remember information about you such as your
          language preference or login information.
        </li>
        <li>
          <strong>Company:</strong> when these terms mention “Company,” “we,”
          “us,” or “our,” it refers to Locumspace, that is responsible for your
          information under this Terms & Conditions.
        </li>
        <li>
          <strong>Country:</strong> where Locumspace or the owners/founders of
          Locumspace are based, in this case, Nigeria.
        </li>
        <li>
          <strong>Device:</strong> any internet-connected device such as a
          phone, tablet, computer, or any other device that can be used to visit
          Locumspace and use the services.
        </li>
        <li>
          <strong>Service:</strong> refers to the service provided by Locumspace
          as described in the relative terms (if available) and on this
          platform.
        </li>
        <li>
          <strong>Third-party service:</strong> refers to advertisers, contest
          sponsors, promotional and marketing partners, and others who provide
          our content or whose products or services we think may interest you.
        </li>
        <li>
          <strong>Website:</strong> Locumspace’s site, which can be accessed via
          this URL:{" "}
          <a href="http://locumspace.co" target="_blank" rel="noreferrer">
            http://locumspace.co
          </a>
        </li>
        <li>
          <strong>You:</strong> a person or entity that is registered with
          Locumspace to use the Services.
        </li>
      </ul>

      <h2>Restrictions</h2>
      <p>You agree not to, and you will not permit others to:</p>
      <ul>
        <li>
          License, sell, rent, lease, assign, distribute, transmit, host,
          outsource, disclose or otherwise commercially exploit the website or
          make the platform available to any third party.
        </li>
        <li>
          Modify, make derivative works of, disassemble, decrypt, reverse
          compile or reverse engineer any part of the website.
        </li>
        <li>
          Remove, alter or obscure any proprietary notice (including any notice
          of copyright or trademark) of Locumspace or its affiliates, partners,
          suppliers or the licensors of the website.
        </li>
      </ul>
      <h2>Payment</h2>
      <p>
        If you register to any of our recurring payment plans, you agree to pay
        all fees or charges to your account for the Service in accordance with
        the fees, charges and billing terms in effect at the time that each fee
        or charge is due and payable. Unless otherwise indicated in an order
        form, you must provide Locumspace with a valid credit card (Visa,
        MasterCard, or any other issuer accepted by us) (“Payment Provider”) as
        a condition to signing up for the Premium plan. Your Payment Provider
        agreement governs your use of the designated credit card account, and
        you must refer to that agreement and not these Terms to determine your
        rights and liabilities with respect to your Payment Provider. By
        providing Locumspace with your credit card number and associated payment
        information, you agree that Locumspace is authorized to verify
        information immediately, and subsequently invoice your account for all
        fees and charges due and payable to Locumspace hereunder and that no
        additional notice or consent is required. You agree to immediately
        notify Locumspace of any change in your billing address or the credit
        card used for payment hereunder. Locumspace reserves the right at any
        time to change its prices and billing methods, either immediately upon
        posting on our Site or by e-mail delivery to your organization’s
        administrator(s).
      </p>
      <br />
      <p>
        Any attorney fees, court costs, or other costs incurred in collection of
        delinquent undisputed amounts shall be the responsibility of and paid
        for by you.
      </p>
      <br />
      <p>
        No contract will exist between you and Locumspace for the Service until
        Locumspace accepts your order by a confirmatory e-mail, SMS/MMS message,
        or other appropriate means of communication.
      </p>
      <br />
      <p>
        You are responsible for any third-party fees that you may incur when
        using the Service.
      </p>
      <h2>Return and Refund Policy</h2>
      <p>
        Thanks for joining Locumspace. We appreciate the fact that you like to
        purchase the stuff we build. We also want to make sure you have a
        rewarding experience while you’re exploring, evaluating, and purchasing
        our products.
      </p>
      <p>
        As with any purchase experience, there are terms and conditions that
        apply to transactions at Locumspace. We’ll be as brief as our attorneys
        will allow. The main thing to remember is that by placing an order or
        making a purchase at Locumspace, you agree to the terms along with
        Locumspace’s <a href={`/${PRIVACY_POLICY}`} rel="noreferrer">Privacy Policy</a>.
      </p>
      <p>
        If, for any reason, you are not completely satisfied with any good or
        service that we provide, don't hesitate to contact us and we will
        discuss any of the issues you are going through with our product team.
      </p>

      <h2>Your Suggestions</h2>
      <p>
        Any feedback, comments, ideas, improvements, or suggestions
        (collectively, "Suggestions") provided by you to Locumspace with respect
        to the website shall remain the sole and exclusive property of
        Locumspace.
      </p>
      <p>
        Locumspace shall be free to use, copy, modify, publish, or redistribute
        the Suggestions for any purpose and in any way without any credit or any
        compensation to you.
      </p>

      <h2>Your Consent</h2>
      <p>
        We've updated our Terms & Conditions to provide you with complete
        transparency into what is being set when you visit our site and how it's
        being used. By using our website, registering an account, or making a
        purchase, you hereby consent to our Terms & Conditions.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        This Terms & Conditions applies only to the Services. The Services may
        contain links to other websites not operated or controlled by
        Locumspace. We are not responsible for the content, accuracy, or
        opinions expressed in such websites, and such websites are not
        investigated, monitored, or checked for accuracy or completeness by us.
      </p>
      <p>
        Please remember that when you use a link to go from the Services to
        another website, our Terms & Conditions are no longer in effect. Your
        browsing and interaction on any other website, including those that have
        a link on our platform, is subject to that website’s own rules and
        policies.
      </p>
      <p>
        Such third parties may use their own cookies or other methods to collect
        information about you.
      </p>

      <h2>Cookies</h2>
      <p>
        Locumspace uses "Cookies" to identify the areas of our website that you
        have visited. A Cookie is a small piece of data stored on your computer
        or mobile device by your web browser. We use Cookies to enhance the
        performance and functionality of our website but are non-essential to
        their use.
      </p>
      <p>
        However, without these cookies, certain functionality like videos may
        become unavailable or you would be required to enter your login details
        every time you visit the website as we would not be able to remember
        that you had logged in previously.
      </p>
      <p>
        Most web browsers can be set to disable the use of Cookies. However, if
        you disable Cookies, you may not be able to access functionality on our
        website correctly or at all. We never place Personally Identifiable
        Information in Cookies.
      </p>

      <h2>Changes To Our Terms & Conditions</h2>
      <p>
        You acknowledge and agree that Locumspace may stop (permanently or
        temporarily) providing the Service (or any features within the Service)
        to you or to users generally at Locumspace’s sole discretion, without
        prior notice to you. You may stop using the Service at any time. You do
        not need to specifically inform Locumspace when you stop using the
        Service.
      </p>
      <p>
        You acknowledge and agree that if Locumspace disables access to your
        account, you may be prevented from accessing the Service, your account
        details, or any files or other materials contained in your account.
      </p>

      <h2>Modifications to Our Website</h2>
      <p>
        Locumspace reserves the right to modify, suspend, or discontinue,
        temporarily or permanently, the website or any service to which it
        connects, with or without notice and without liability to you.
      </p>

      <h2>Updates to Our Website</h2>
      <p>
        Locumspace may from time to time provide enhancements or improvements to
        the features/functionality of the website, which may include patches,
        bug fixes, updates, upgrades, and other modifications ("Updates").
      </p>
      <p>
        Updates may modify or delete certain features and/or functionalities of
        the website. You agree that Locumspace has no obligation to (i) provide
        any Updates, or (ii) continue to provide or enable any particular
        features and/or functionalities of the website to you.
      </p>
      <p>
        You further agree that all Updates will be (i) deemed to constitute an
        integral part of the website, and (ii) subject to the terms and
        conditions of this Agreement.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        We may display, include or make available third-party content (including
        data, information, applications, and other products/services) or provide
        links to third-party websites or services ("Third-Party Services").
      </p>
      <p>
        You acknowledge and agree that Locumspace shall not be responsible for
        any Third-Party Services, including their accuracy, completeness,
        timeliness, validity, copyright compliance, legality, decency, quality,
        or any other aspect thereof.
      </p>
      <p>
        Third-Party Services and links thereto are provided solely as a
        convenience to you and you access and use them entirely at your own risk
        and subject to such third parties' terms and conditions.
      </p>

      <h2>Term and Termination</h2>
      <p>
        This Agreement shall remain in effect until terminated by you or
        Locumspace. Locumspace may, in its sole discretion, at any time and for
        any or no reason, suspend or terminate this Agreement with or without
        prior notice.
      </p>

      <h2>Copyright Infringement Notice</h2>
      <p>
        If you are a copyright owner or such owner’s agent and believe any
        material on our website constitutes an infringement on your copyright,
        please contact us at:
      </p>
      <ul>
        <li>
          Email: <a href="mailto:info@locumspace.co">info@locumspace.co</a>
        </li>
      </ul>

      <h2>Indemnification</h2>
      <p>
        You agree to indemnify and hold Locumspace and its parents,
        subsidiaries, affiliates, officers, employees, agents, partners, and
        licensors (if any) harmless from any claim or demand, including
        reasonable attorneys' fees, due to or arising out of your: (a) use of
        the website; (b) violation of this Agreement or any law or regulation;
        or (c) violation of any right of a third party.
      </p>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem;
  & > h2 {
    margin: 1rem;
  }
  & > p {
    margin: 1rem;
  }
`;
