import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import styled from '@emotion/styled';

import Footer from './footer/Footer';
import { Header } from './header/Header';
import HamburgerMenu from './humbugerMeun/HamburgerMenu';

const LandingPageLayout = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <HamburgerMenu
        open={open}
        toggleNav={() => {
          setOpen(!open);
        }}
      />
      <Header
        open={open}
        toggleNav={() => {
          setOpen(!open);
        }}
      />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
};

export default LandingPageLayout;

const Main = styled.main`
  /* border: 2px solid red; */
`;
