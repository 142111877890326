import React from 'react';

const AppStoreIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.8333 31.9337C32.8166 30.4337 33.1833 29.667 33.9333 27.9837C28.3999 25.8837 27.5166 18.0003 32.9833 14.9837C31.3166 12.8837 28.9666 11.667 26.7499 11.667C25.1499 11.667 24.0499 12.0837 23.0666 12.467C22.2333 12.7837 21.4833 13.067 20.5499 13.067C19.5499 13.067 18.6666 12.7503 17.7333 12.417C16.7333 12.0503 15.6666 11.667 14.3333 11.667C11.8499 11.667 9.19993 13.1837 7.51659 15.7837C5.14993 19.4503 5.56659 26.317 9.38325 32.1837C10.7499 34.2837 12.5833 36.6337 14.9666 36.667C15.9666 36.6837 16.6166 36.3837 17.3333 36.067C18.1499 35.7003 19.0333 35.3003 20.5833 35.3003C22.1333 35.2837 22.9999 35.7003 23.8166 36.067C24.5166 36.3837 25.1499 36.6837 26.1333 36.667C28.5333 36.6337 30.4666 34.0337 31.8333 31.9337Z"
        fill="white"
      />
      <path
        opacity="0.4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.3999 3.3335C26.6666 5.16683 25.9166 6.98349 24.9332 8.25016C23.8832 9.61682 22.0499 10.6835 20.2832 10.6168C19.9666 8.85017 20.7832 7.03349 21.7832 5.81683C22.8999 4.48349 24.7832 3.45016 26.3999 3.3335Z"
        fill="white"
      />
    </svg>
  );
};

export default AppStoreIcon;
