import {
  ABOUT_US,
  CONTACT_US,
  FAQS,
  LOOKING_FOR_LOCUM,
  LOOKING_FOR_WORK,
} from '../../routes/routeConstants';

export const navConfig = [
  {
    title: 'AboutUs',
    path: ABOUT_US,
  },

  {
    title: 'Looking for Work?',
    path: LOOKING_FOR_WORK,
  },

  {
    title: 'Looking for Locum',
    path: LOOKING_FOR_LOCUM,
  },

  {
    title: 'FAQs',
    path: FAQS,
  },

  {
    title: 'Contact us',
    path: CONTACT_US,
  },
];
