import React from 'react';

import Hero from '../../components/Hero/Hero';
import Page from '../../components/Page/Page';
import Advantages from './Advantages/Advantages';
import BenefitsOfLocum from './BenefitsOfLocum/BenefitsOfLocum';
import HowItWorks from './HowItWorks/HowItWorks';

const LookingForWork = () => {
  return (
    <Page title="LocumSpace | Looking for work?">
      <Hero
        imgSrc="/static/img/looking-for-work-hero.png"
        title="Choose when and where you work"
        description="Thousands of healthcare workers are already earning great money with Locumspace. Choose shifts that suit your schedule."
      />
      <BenefitsOfLocum />
      <HowItWorks />
      <Advantages />
    </Page>
  );
};

export default LookingForWork;
