import React from "react";

import styled from "@emotion/styled";

import CompanyLogo from "../../../components/CompanyLogo/CompanyLogo";
import HamburgerIcon from "../../../components/Icons/HamburgerIcon";
// import AuthNav from '../nav/AuthNav';
import Nav from "../nav/Nav";
import WaitinListNav from "../nav/WaitingListNav";

interface IHeader {
  open: boolean;
  toggleNav: (...arg: any) => void;
}

export const Header: React.FC<IHeader> = ({ open, toggleNav }) => {
  return (
    <StyledHeader>
      <CompanyLogo />
      <Nav />
      <WaitinListNav mobileVisible={false} />
      {/* <AuthNav /> */}
      <HamburgerIconContainer onClick={() => toggleNav()}>
        <HamburgerIcon />
      </HamburgerIconContainer>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  /* border: 2px solid red; */
  display: flex;
  padding: 1.17rem 7.12rem;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    padding: 1.5rem;
  }
`;

const HamburgerIconContainer = styled.div`
  display: None;

  @media (max-width: 480px) {
    display: block;
    cursor: pointer;
  }
`;
