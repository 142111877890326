export const RecenltyAddedJobData = [
  {
    link: '/',
    title: 'Phamarcy SHO Position in Lagos',
    description:
      'Join our General Medicine SHO Position in Lagos: Elevate Your Career, Impact Patient Care',
  },
  {
    link: '/',
    title: 'General Medicine SHO Position in Lagos',
    description:
      'Join our General Medicine SHO Position in Lagos: Elevate Your Career, Impact Patient Care',
  },
  {
    link: '/',
    title: 'Radiography SHO Position in Lagos',
    description:
      'Join our General Medicine SHO Position in Lagos: Elevate Your Career, Impact Patient Care',
  },
];
