import React, { useEffect, useReducer } from 'react';

import styled from '@emotion/styled';

import { sleep } from '../../utils/utils';
import CarouselControl from './CarouselControl';

export enum CarouselViewReducerActionType {
  CLICK_INDEX = 'clicked-index',
}

type State = { index: number };

export interface ICarouselViewReducerActionType {
  type: CarouselViewReducerActionType;
  payload: State;
}

function segmentedViewReducer(
  state: State,
  action: ICarouselViewReducerActionType
) {
  switch (action.type) {
    case CarouselViewReducerActionType.CLICK_INDEX:
      return { ...action.payload };
    default:
      return state;
  }
}

interface ICarousel {
  children: React.ReactNode[];
}

const Carousel: React.FC<ICarousel> = ({ children }) => {
  const [state, dispatch] = useReducer(segmentedViewReducer, { index: 0 });

  useEffect(() => {
    const slideCarousel = async () => {
      if (state.index + 1 < children.length) {
        await sleep(3000);
        handleStateChange(state.index + 1);
      } else {
        await sleep(3000);
        handleStateChange(0);
      }
    };
    slideCarousel();
  }, [state.index, children.length]);

  const handleStateChange = (index: any) =>
    dispatch({
      type: CarouselViewReducerActionType.CLICK_INDEX,
      payload: { index },
    });

  return (
    <Container>
      <CarouselContent>
        {React.Children.map(children, (child: any, index: number) => {
          const computedClass =
            state?.index === index ? 'showCarouselView' : '';
          return React.cloneElement(child, {
            ...child.props,
            className: computedClass,
          });
        })}
      </CarouselContent>
      <ControlBox>
        {children.map((_, index) => (
          <CarouselControl
            className={index === state?.index ? 'active' : ''}
            key={index}
            // onClick={() => handleStateChange(index)}
          />
        ))}
      </ControlBox>
    </Container>
  );
};

export default Carousel;

const Container = styled.div`
  display: None;
  flex-direction: column;
  gap: 1.31rem;
  align-items: center;

  @media (max-width: 480px) {
    display: flex;
  }
`;

const CarouselContent = styled.div`
  display: grid;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }
`;

const ControlBox = styled.div`
  display: flex;
  gap: 1rem;
`;
