import React from 'react';

const WwwIcon = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
      <rect width="100" height="100" rx="50" fill="#EDF4FF" />
      <path
        d="M31.9541 60.4167C33.7823 63.5849 36.4127 66.2156 39.5806 68.0442C42.7485 69.8729 46.3421 70.8348 49.9999 70.8334C53.6577 70.8348 57.2514 69.8729 60.4193 68.0442C63.5872 66.2156 66.2176 63.5849 68.0458 60.4167M31.9541 39.5834C33.7823 36.4152 36.4127 33.7844 39.5806 31.9558C42.7485 30.1272 46.3421 29.1652 49.9999 29.1667C53.6577 29.1652 57.2514 30.1272 60.4193 31.9558C63.5872 33.7844 66.2176 36.4152 68.0458 39.5834"
        stroke="#2857D1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.0833 70.7292C52.0833 70.7292 55.0166 66.8688 56.8645 60.4167M52.0833 29.2709C52.0833 29.2709 55.0166 33.1292 56.8645 39.5834M47.9166 70.7292C47.9166 70.7292 44.9833 66.8709 43.1353 60.4167M47.9166 29.2709C47.9166 29.2709 44.9833 33.1292 43.1353 39.5834M43.7499 45.8334L46.8749 56.25L49.9999 45.8334L53.1249 56.25L56.2499 45.8334M27.0833 45.8334L30.2083 56.25L33.3333 45.8334L36.4583 56.25L39.5833 45.8334M60.4166 45.8334L63.5416 56.25L66.6666 45.8334L69.7916 56.25L72.9166 45.8334"
        stroke="#2857D1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WwwIcon;
