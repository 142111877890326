import React from 'react';

const RightArrowButtonIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path
        opacity="0.4"
        d="M10.1932 33.667H23.8065C29.7048 33.667 33.6665 29.8737 33.6665 24.2253V9.77533C33.6665 4.12699 29.7048 0.33366 23.8065 0.33366H10.1932C4.2965 0.33366 0.333167 4.12866 0.333167 9.77699L0.333167 24.2253C0.333167 29.8737 4.2965 33.667 10.1932 33.667Z"
        fill="#2857D1"
      />
      <path
        d="M18.4257 24.1326L24.7007 17.886C25.1723 17.416 25.1723 16.5843 24.7007 16.1126L18.4257 9.86595C17.9357 9.37929 17.144 9.38095 16.6573 9.87096C16.1707 10.361 16.1707 11.151 16.6607 11.6393L20.789 15.751H10.1973C9.50567 15.751 8.94733 16.311 8.94733 17.001C8.94733 17.691 9.50567 18.251 10.1973 18.251H20.789L16.6607 22.361C16.4157 22.606 16.294 22.926 16.294 23.2476C16.294 23.566 16.4157 23.886 16.6573 24.1293C17.144 24.6176 17.9357 24.6193 18.4257 24.1326Z"
        fill="#2857D1"
      />
    </svg>
  );
};

export default RightArrowButtonIcon;
