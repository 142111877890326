import React from 'react';

import styled from '@emotion/styled';

import BulletCard from '../../../components/BulletCard/BulletCard';
import TitledSection from '../../../components/TitledSection/TitledSection';

const list1 = [
  { content: 'Book high Paying shifts instantly' },
  { content: 'Manage your availability with ease' },
  { content: 'Find flexible shifts fast' },
  { content: 'Earn any time, anywhere' },
];

const list2 = [
  { content: 'Work with vetted healthcare professionals' },
  { content: 'Manage your rota' },
  { content: 'Manage locum applications ' },
  { content: 'Make payments easier' },
];

const LocumSpaceGotYouCovered = () => {
  return (
    <TitledSection title={`Here at Locumspace we've got you covered`}>
      <ContentArea>
        <BulletCard
          title="For Healthcare Workers"
          description="Our intuitive platform lets you manage your work away personal lives."
          btnLink="/"
          btnText="Find Jobs"
          bulletList={list1}
        />
        <BulletCard
          title="For Organisations"
          description="With one easy-to-use solution, you can manage your workforce from end to end."
          btnLink="/"
          btnText="Get Started"
          bulletList={list2}
          className="business"
        />
      </ContentArea>
    </TitledSection>
  );
};

export default LocumSpaceGotYouCovered;

// Styles

const ContentArea = styled.div`
  display: flex;
  /* border: 2px solid blue; */
  gap: 1.25rem;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
