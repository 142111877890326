import React from 'react';

const ArrowHead = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.619275 9.51034L9.75009 3.88473C9.95459 3.75939 10.2164 3.77139 10.4079 3.9194C11.2754 4.58743 12.0504 5.2648 12.5675 5.83416C12.5675 5.83416 13.013 6.29018 13.2057 6.57953C13.517 6.97421 13.6668 7.49024 13.6668 7.9916C13.6668 8.55429 13.5027 9.08632 13.1758 9.51168C13.1015 9.58768 12.8189 9.92236 12.5519 10.1957C10.994 11.8971 6.92748 14.7079 4.78871 15.56C4.4774 15.6973 3.64638 15.984 3.21524 16C2.79973 16 2.39855 15.9093 2.01299 15.7107C1.53887 15.4373 1.16765 15.0133 0.959239 14.5106C0.825077 14.1612 0.617973 13.1132 0.617973 13.0825C0.483811 12.3345 0.383516 11.2838 0.334019 10.057C0.324901 9.83702 0.435617 9.62368 0.619275 9.51034Z"
        fill="#11171D"
      />
      <path
        opacity="0.4"
        d="M1.23003 6.48034C0.827542 6.72968 0.320854 6.411 0.340392 5.93231C0.388586 4.80959 0.471949 3.82021 0.573547 3.08417C0.589177 3.06817 0.796281 1.76277 1.03465 1.32141C1.45016 0.501373 2.26555 1.43051e-05 3.14216 1.43051e-05H3.2151C3.7804 0.0146818 4.98265 0.51604 4.98265 0.546708C5.58833 0.798721 6.37897 1.22541 7.22953 1.73877C7.61248 1.97078 7.6216 2.54014 7.23865 2.77615L1.23003 6.48034Z"
        fill="#11171D"
      />
    </svg>
  );
};

export default ArrowHead;
