import {
  ABOUT_US,
  CONTACT_US,
  HOME,
  LOOKING_FOR_LOCUM,
  LOOKING_FOR_WORK,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
} from '../../../routes/routeConstants';

export const footerNavCompanyConfig = [
  {
    title: 'Home',
    path: HOME,
  },

  {
    title: 'AboutUs',
    path: ABOUT_US,
  },

  {
    title: 'Jobs',
    path: LOOKING_FOR_WORK,
  },

  {
    title: 'Locum',
    path: LOOKING_FOR_LOCUM,
  },

  {
    title: 'Contact us',
    path: CONTACT_US,
  },
];

export const footerNavLegalConfig = [
  {
    title: 'Terms & Condition',
    path: TERMS_CONDITIONS,
  },

  {
    title: 'Privacy policy',
    path: PRIVACY_POLICY,
  },

  {
    title: 'Cookie policy',
    path: LOOKING_FOR_WORK,
  },
];

export const footerNavContactConfig = [
  {
    title: 'info@locumspace.co',
    path: CONTACT_US,
  },

  {
    title: 'Lagos, Nigeria',
    path: CONTACT_US,
  },
];
