import React from 'react';

import styled from '@emotion/styled';

import Badge from '../../../components/Badge/Badge';
import Card from '../../../components/Card/Card';
import AfflilateHr from '../../../components/Icons/AfflilateHr';
import QualitySupportIcon from '../../../components/Icons/QualitySupportIcon';
import SeamlessTransitionIcon from '../../../components/Icons/SeamlessTransitionIcon';
import ServiceSecurity from '../../../components/Icons/ServiceSecurity';
import TextCard from '../../../components/TextCard/TextCard';

const WhyChooseUs = () => {
  return (
    <Container>
      <LeftSection>
        <Badge>WHY CHOOSE US</Badge>
        <TextCard
          className="bigGap"
          title="Managing Your Locum Job Can Be Made Easier With Our Services"
        >
          Experience the Future of Locum with Us - Innovative Solutions,
          Expertise, and Customer Satisfaction Guaranteed.
        </TextCard>
      </LeftSection>
      <CardArea>
        <Card
          icon={<QualitySupportIcon />}
          title="Quality Support"
          description={`Unmatched Quality Support - We're Here to Help You Every Step of the
        Way.`}
        />
        <Card
          showFooter
          icon={<SeamlessTransitionIcon />}
          title="Seamless Transactions"
          description={`Experience Seamless Transactions - Fast, Secure, and Hassle-Free.`}
        />
        <Card
          icon={<ServiceSecurity />}
          title="Service Security"
          description={`Your Service Security is Our Top Priority - Trust Us to Keep Your Information Safe and Secure.`}
        />
        <Card
          showFooter
          icon={<AfflilateHr />}
          title="Agency HR"
          description={`Rewardly manage verified accounts that are assigned verified to post credible jobs.`}
        />
      </CardArea>
    </Container>
  );
};

export default WhyChooseUs;

// Styles

const Container = styled.section`
  display: flex;
  gap: 4.69rem;
  margin: 6.06rem 6.25rem;
  /* border: 2px solid yellow; */

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    margin: 0;
    padding: 1.5rem;
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  flex-basis: 40%;
`;

const CardArea = styled.div`
  flex-basis: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  /* border: 2px solid red; */

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;
