import React from 'react';

import styled from '@emotion/styled';

import RectangularPlanCard from '../../../components/Card/RectangularPlanCard';
import Img from '../../../components/Img/Img';

const KeyPoints = () => {
  return (
    <Container>
      <Img src="/static/img/checkup-doctor.png" />
      <ContentArea>
        <RectangularPlanCard
          title="Fast & Effective"
          description="Find the right locum, as soon as you need them"
        />
        <RectangularPlanCard
          title="Reliable"
          description="Find the right locum, as soon as you need them"
        />
        <RectangularPlanCard
          title="Risk free"
          description="Only pay after we have filled your shift"
        />
        <RectangularPlanCard
          title="Flexibility"
          description="Manage your own bookings OR offload that hassle to us"
        />
      </ContentArea>
    </Container>
  );
};

export default KeyPoints;

// Styled
const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  margin: 7.75rem 8.44rem;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    margin: 0;
    flex-direction: column;
    padding: 1.5rem;
  }
`;

const ContentArea = styled.div`
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
