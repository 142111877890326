import React from 'react';

const ArrowIcon = () => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M0.710905 5.34079L9.18891 5.34079C9.58112 5.34079 9.89943 5.6591 9.89943 6.05131C9.89943 6.44353 9.58112 6.76184 9.18891 6.76184L0.710905 6.76184C0.318695 6.76184 0.000378847 6.44353 0.000378847 6.05131C0.000378847 5.6591 0.318695 5.34079 0.710905 5.34079Z"
        fill="currentColor"
      />
      <mask
        id="mask0_1069_49"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="8"
        y="0"
        width="10"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.47857 11.4998V0.603374H17.418V11.4998H8.47857Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_1069_49)">
        <path
          d="M9.18909 0.603089C9.32173 0.603089 9.45246 0.640037 9.56804 0.712036L17.0864 5.44983C17.2929 5.58056 17.4189 5.80698 17.4189 6.0514C17.4189 6.29583 17.2929 6.52225 17.0864 6.65298L9.56804 11.3908C9.3492 11.5281 9.07257 11.5367 8.84615 11.4107C8.61878 11.2856 8.47857 11.0478 8.47857 10.7892V1.31361C8.47857 1.05498 8.61878 0.817194 8.84615 0.692142C8.9532 0.632458 9.07162 0.603089 9.18909 0.603089Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default ArrowIcon;
