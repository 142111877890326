import React from 'react';

import styled from '@emotion/styled';

const LocumJobs = () => {
  return (
    <Container>
      <Title>Locumspace: easier way to manage your Job</Title>
      <P>
        Join our thriving community of locum professionals and unlock a world of
        possibilities. Enhance your skills, expand your network, and shape your
        career on your terms. Embrace the freedom and flexibility that locum
        work offers, all while making a positive impact on patient care.
      </P>
    </Container>
  );
};

export default LocumJobs;

// Styled
const Container = styled.section`
  display: flex;
  margin: 7.12rem;
  /* border: 2px solid red; */
  gap: 1rem;

  @media (max-width: 480px) {
    margin: 0;
    padding: 1.5rem;
    padding-top: 0rem;
    flex-direction: column;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.blackBlackMain};
  flex-basis: 40%;

  @media (max-width: 480px) {
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

const P = styled.p`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.blackBlack3};
  flex-basis: 60%;
`;
