import styled from '@emotion/styled';

const CarouselControl = styled.div`
  display: flex;
  width: 4rem;
  height: 0.25rem;
  border-radius: 2px;
  background-color: #b9b9b950;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.palette.blackBlack2};
    border: 1px solid ${({ theme }) => theme.palette.blackBlack2};
  }
`;

export default CarouselControl;
