import React from 'react';

const StarIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M9.73223 2.33521L11.4021 5.69092C11.5251 5.93404 11.7599 6.10288 12.0307 6.1404L15.7815 6.68667C16.0006 6.71744 16.1994 6.833 16.3336 7.00934C16.4664 7.18343 16.5234 7.40404 16.4912 7.6209C16.4649 7.80099 16.3802 7.96757 16.2504 8.09514L13.5325 10.7297C13.3337 10.9136 13.2437 11.186 13.2917 11.4523L13.9609 15.1562C14.0321 15.6035 13.7358 16.0252 13.2917 16.11C13.1087 16.1392 12.9212 16.1085 12.7561 16.0244L9.41041 14.2813C9.1621 14.156 8.86879 14.156 8.62049 14.2813L5.27477 16.0244C4.86368 16.2428 4.35432 16.0942 4.12552 15.689C4.04075 15.5277 4.01075 15.3438 4.0385 15.1645L4.70765 11.4599C4.75566 11.1942 4.66489 10.9203 4.46685 10.7365L1.74901 8.10339C1.42569 7.79123 1.41594 7.27722 1.72726 6.95381C1.73401 6.94706 1.74151 6.93955 1.74901 6.93205C1.87804 6.80073 2.04758 6.71744 2.23062 6.69568L5.98142 6.14865C6.25148 6.11038 6.48628 5.94305 6.61006 5.69842L8.2199 2.33521C8.36318 2.04706 8.66025 1.86772 8.98282 1.87523H9.08334C9.36315 1.90899 9.60695 2.08233 9.73223 2.33521Z"
        fill="#2857D1"
      />
      <path
        d="M8.99401 14.1878C8.84873 14.1923 8.7072 14.2314 8.5799 14.3012L5.25054 16.0404C4.84317 16.2348 4.35567 16.0839 4.12728 15.6943C4.04266 15.5352 4.01195 15.3528 4.04041 15.1741L4.70538 11.4774C4.75031 11.2086 4.66045 10.9354 4.465 10.7463L1.74595 8.11386C1.4232 7.79785 1.41721 7.27917 1.73322 6.95566C1.73772 6.95115 1.74146 6.9474 1.74595 6.94365C1.87475 6.81604 2.041 6.73197 2.21997 6.7057L5.97392 6.15325C6.24575 6.11872 6.48164 5.94908 6.60145 5.70288L8.23318 2.29734C8.3882 2.02261 8.68549 1.85898 9 1.87624C8.99401 2.09918 8.99401 14.0362 8.99401 14.1878Z"
        fill="#2857D1"
      />
    </svg>
  );
};

export default StarIcon;
