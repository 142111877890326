import React from 'react';

import styled from '@emotion/styled';

import LeftArrowButtonIcon from '../../../components/Icons/LeftArrowButtonIcon';
import RightArrowButtonIcon from '../../../components/Icons/RightArrowButtonIcon';
import Img from '../../../components/Img/Img';
import ReviewStars from '../../../components/ReviewStars/ReviewStars';

const DoctorReview = () => {
  return (
    <Container>
      <Blue1></Blue1>
      <Blue2></Blue2>
      <Img src="/static/img/female-doctor.png" />
      <Content>
        <DoctorName>Dr. Janet Agbo</DoctorName>
        <Title>Doctor, MEDCROSS</Title>
        <ReviewStars starNumbers={5} />
        <P>
          Working as a locum doctor has been an incredible experience for me. It
          has allowed me to explore various healthcare settings, gain exposure
          to diverse patient populations, and enhance my clinical skills. The
          flexibility of locum work has been invaluable, as it has allowed me to
          maintain a healthy work-life balance and pursue personal interests. I
          highly recommend the locum lifestyle to fellow doctors looking for new
          challenges and rewarding opportunities.
        </P>
        <ButtonArea>
          <LeftArrowButtonIcon />
          <RightArrowButtonIcon />
        </ButtonArea>
      </Content>
    </Container>
  );
};

export default DoctorReview;

// style
const Blue1 = styled.div`
  position: absolute;
  height: 100%;
  width: 22.25rem;
  background-color: ${({ theme }) => theme.palette.mainBlue};
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 480px) {
    width: 4rem;
  }
`;

const Blue2 = styled.div`
  position: absolute;
  height: 98%;
  width: 6rem;
  background-color: ${({ theme }) => theme.palette.mainBlue};
  top: 0;
  right: 0;
  z-index: -1;

  @media (max-width: 480px) {
    width: 0rem;
  }
`;

const Container = styled.section`
  display: flex;
  gap: 3.19rem;
  /* border: 2px solid green; */
  color: ${({ theme }) => theme.palette.blackBlack3};
  align-items: center;
  padding: 5.69rem 9.06rem;
  position: relative;

  @media (max-width: 480px) {
    /* border: 1px solid red; */
    flex-direction: column;
    padding: 1.5rem;
    /* border: 1px solid red; */
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    padding-left: 3.5rem;
  }
`;

const DoctorName = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.blackBlackMain};
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 300;
`;

const P = styled.p`
  font-weight: 400;
  margin-top: 1rem;
`;

const ButtonArea = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-top: 5rem;
`;
