import React from 'react';

const DangerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M4.7227 21.1675C4.7097 21.1675 4.6977 21.1675 4.6837 21.1665C4.3687 21.1505 4.0597 21.0825 3.7657 20.9635C2.3187 20.3755 1.6207 18.7225 2.2077 17.2765L9.5287 4.45049C9.7807 3.99449 10.1627 3.61249 10.6287 3.35449C11.9937 2.59849 13.7197 3.09549 14.4747 4.45949L21.7477 17.1875C21.9097 17.5685 21.9787 17.8785 21.9957 18.1945C22.0347 18.9505 21.7767 19.6755 21.2707 20.2365C20.7647 20.7975 20.0697 21.1285 19.3147 21.1665L4.7947 21.1675H4.7227Z"
        fill="#E01D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1245 10.0205C11.1245 9.53851 11.5175 9.14551 11.9995 9.14551C12.4815 9.14551 12.8745 9.53851 12.8745 10.0205V12.8485C12.8745 13.3315 12.4815 13.7235 11.9995 13.7235C11.5175 13.7235 11.1245 13.3315 11.1245 12.8485V10.0205ZM11.1245 16.2696C11.1245 15.7846 11.5175 15.3896 11.9995 15.3896C12.4815 15.3896 12.8745 15.7796 12.8745 16.2586C12.8745 16.7516 12.4815 17.1446 11.9995 17.1446C11.5175 17.1446 11.1245 16.7516 11.1245 16.2696Z"
        fill="#E01D1D"
      />
    </svg>
  );
};

export default DangerIcon;
