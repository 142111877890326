import React, { useEffect } from 'react';

import styled from '@emotion/styled';

interface IInfiniteScrolling {
  title?: string;
}

const countList = [
  1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 1, 2, 3, 4, 5, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 11, 12, 13, 14, 15,
];

const InfiniteScrolling: React.FC<IInfiniteScrolling> = ({ title }) => {
  useEffect(() => {
    const scrollers = document.querySelectorAll('.scroller');

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute('data-animated', 'true');
      });
    }
  }, []);
  return (
    <Container>
      <Title>{title}</Title>
      <Scroller className="scroller">
        <TagList className="scroller__inner">
          {countList.map((_, index) => (
            <Li key={index}>
              <Image src="/static/svg/companyLogo.svg" alt="chipper" />
            </Li>
          ))}
        </TagList>
      </Scroller>
    </Container>
  );
};

export default InfiniteScrolling;

const Image = styled.img`
  @media (max-width: 480px) {
    height: 30px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 3.37rem;
  margin-bottom: 3.37rem;
  /* border: 2px solid red; */

  @media (max-width: 480px) {
    width: 100%;
    margin-top: 5.38rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
`;

const Title = styled.h2`
  text-align: center;

  font-size: 1.25rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.blackBlackMain};

  /* border: 1px solid green; */

  @media (max-width: 480px) {
    font-size: 1.25rem;
    /* text-align: left; */
  }
`;

const Scroller = styled.div`
  &.scroller {
    max-width: 100%;
  }

  &.scroller[data-animated='true'] {
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
  }

  &.scroller[data-animated='true'] .scroller__inner {
    width: fit-content;
    flex-wrap: nowrap;
    animation: scroll 10s linear infinite;
  }

  @keyframes scroll {
    to {
      transform: translate(-88%);
    }
  }
`;

const TagList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  &.scroller__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
  }
`;

const Li = styled.div`
  flex-shrink: 0;
  padding: 1rem;

  box-shadow: 0;
  width: fit-content;
`;
