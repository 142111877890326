import React from 'react';

import styled from '@emotion/styled';

import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import Img from '../../../components/Img/Img';
import { ButtonLink } from '../../../components/Link/Link';

const GettingStarted = () => {
  return (
    <Container>
      <TextSection>
        <Title>Connecting Medical Practices and Medical Professionals</Title>
        <Description>
          Empowering freelance healthcare professionals to manage their hours in
          locum practice. Your next few hours of work is just a click away
        </Description>
      </TextSection>
      <ButtonArea>
        <ButtonLink to="https://locum-user.web.app/account/signup">
          <SecondaryButton className="big fullWidth" arrowIcon>
            Get Started
          </SecondaryButton>
        </ButtonLink>
        <ButtonLink to="/">
          <PrimaryButton className="big fullWidth">Learn More</PrimaryButton>
        </ButtonLink>
      </ButtonArea>

      <Img src={'/static/svg/GetStarted.svg'} alt="get started" />
    </Container>
  );
};

export default GettingStarted;

// Styles

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  text-align: center;
  margin: 0 16rem;
  margin-top: 6.25rem;
  /* border: 2px solid blue; */

  @media (max-width: 480px) {
    /* padding: 1rem; */
    margin: 0;
    padding: 1.5rem;
    padding-top: 1rem;
    gap: 2rem;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* border: 2px solid blue; */
`;

const Title = styled.h1`
  font-size: 3.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.blackBlackMain};
  line-height: 6rem;

  @media (max-width: 480px) {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    line-height: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.blackBlack3};

  @media (max-width: 480px) {
    font-size: 1.25rem;

    font-weight: 300;
    text-align: left;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  gap: 3.38rem;

  @media (max-width: 480px) {
    gap: 1rem;
    /* border: 2px solid red; */
    width: 100%;
    flex-direction: column;
  }
`;
