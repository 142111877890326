import React from 'react';

const PlayStoreIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M29.15 15.9999L9.33335 27.9499C8.16669 28.6499 6.66669 27.8166 6.66669 26.4499V13.1166C6.66669 7.2999 12.95 3.66657 18 6.56657L25.65 10.9666L29.1334 12.9666C30.2834 13.6499 30.3 15.3166 29.15 15.9999Z"
        fill="white"
      />
      <path
        d="M30.15 25.7667L23.4 29.6667L16.6667 33.5501C14.25 34.9334 11.5167 34.6501 9.53334 33.2501C8.56668 32.5834 8.68334 31.1001 9.70001 30.5001L30.8833 17.8001C31.8833 17.2001 33.2 17.7667 33.3833 18.9167C33.8 21.5001 32.7333 24.2834 30.15 25.7667Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayStoreIcon;
