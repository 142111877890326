import React from 'react';

import Hero from '../../components/Hero/Hero';
import Page from '../../components/Page/Page';
import TrustedBy from '../../components/TrustedBy/TrustedBy';
import ClientSuccessStories from './ClientSuccessStories/ClientSuccessStories';
import KeyPoints from './KeyPoints/KeyPoints';
import SolutionWeProvide from './SolutionWeProvide/SolutionWeProvide';

const LookingForLocums = () => {
  return (
    <Page title="LocumSpace | Looking for Locums">
      <Hero
        imgSrc="/static/img/doctor-folds-arm.png"
        title="Find Compliant Workers Fast"
        description="Get access to thousands of verified locums when you need them. You can book quality locums without calling, texting, or emailing"
      />
      <TrustedBy />
      <KeyPoints />
      <SolutionWeProvide />
      <ClientSuccessStories />
    </Page>
  );
};

export default LookingForLocums;
