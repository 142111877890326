import { Form, Formik } from "formik";

import styled from "@emotion/styled";

interface IFormComponent {
  initialValues: any;
  schema: any;
  onSubmit: any;
  children: any;
  className?: any;
}

export const FormComponent: React.FC<IFormComponent> = ({
  initialValues,
  schema,
  onSubmit,
  children,
  className,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <StyledForm className={className}>{children}</StyledForm>
    </Formik>
  );
};

const StyledForm = styled(Form)`
  /* border: 2px solid orange; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  /* border: 2px solid red; */

  &.flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content:start;
    align-items:center;
  }

  &.smflex {
    @media (max-width: 480px) {
      flex-direction: column;
      width: 90%;
    }
  }

  & > .spanTwo {
    /* border: 2px solid green; */
    grid-column: span 2;
  }

  & > .margin {
    /* border: 2px solid green; */
    grid-column: span 2;
    margin-left: 15%;
    margin-right: 15%;
  }

  &.horizontalForm {
    grid-template-columns: 5fr 1fr;
    align-items: last baseline;
  }

  &.contact {
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
  }
`;
