import React from 'react';

import styled from '@emotion/styled';

import AppStoreIcon from '../Icons/AppStoreIcon';
import PlayStoreIcon from '../Icons/PlayStoreIcon';
import { ButtonLink } from '../Link/Link';

interface IAppDownloadBtn {
  link: string;
  platform: string;
}

const AppDownloadBtn: React.FC<IAppDownloadBtn> = ({ link, platform }) => {
  return (
    <ButtonLink to={link}>
      <StyledButton>
        {platform === 'PlayStore' ? <PlayStoreIcon /> : <AppStoreIcon />}
        <TextArea>
          <Title>Download on</Title>
          <Platform>{platform}</Platform>
        </TextArea>
      </StyledButton>
    </ButtonLink>
  );
};

export default AppDownloadBtn;

const StyledButton = styled.button`
  display: flex;
  gap: 0.5rem;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 400;
  width: fit-content;
  align-items: center;
  padding: 0.5rem 3rem 0.5rem 1rem;
  background-color: #ffffff20;

  @media (max-width: 480px) {
    border-radius: 10px;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
  }
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 0.875rem;

  @media (max-width: 480px) {
    font-size: 0.5rem;
  }
`;

const Platform = styled.div`
  font-weight: 500;
  font-size: 1.25rem;

  @media (max-width: 480px) {
    font-size: 0.875rem;
  }
`;
