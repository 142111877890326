import React from 'react';
import { Toaster } from 'react-hot-toast';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { ToastWrapper } from './components/ToastWrapper/ToastWrapper';
import Router from './routes/routes';

function App() {
  return (
    <>
      <ScrollToTop />
      <ToastWrapper>
        <Toaster position="top-center" reverseOrder={false} />
      </ToastWrapper>
      <Router />
    </>
  );
}

export default App;
