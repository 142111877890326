import React from 'react';

import styled from '@emotion/styled';

import ActionButtonCard from '../../../components/Card/ActionButtonCard';
import Carousel from '../../../components/Carousel/Carousel';
import CarouselCard from '../../../components/Carousel/CarouselCard';
import TitledSection from '../../../components/TitledSection/TitledSection';
import { RecenltyAddedJobData } from './RecenltyAddedJobData';

const RecentlyAddedLocum = () => {
  return (
    <TitledSection title={`Recently added locum jobs`}>
      {/* Only on larger screen */}
      <CardArea>
        {RecenltyAddedJobData.map((data, index) => {
          return (
            <ActionButtonCard
              className="hideSmallScreen"
              key={index}
              link={data.link}
              title={data.title}
              description={data.description}
            />
          );
        })}
      </CardArea>
      {/* Only on mobile screen */}
      <Carousel>
        {RecenltyAddedJobData.map((data, index) => {
          return (
            <CarouselCard
              className="showCarouselView"
              key={index}
              link={data.link}
              title={data.title}
              description={data.description}
            />
          );
        })}
      </Carousel>
    </TitledSection>
  );
};

export default RecentlyAddedLocum;

// Styles
const CardArea = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 480px) {
    flex-direction: column;
    /* border: 1px solid red; */
  }
`;
