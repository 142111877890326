export const faqConfig = [
  {
    title: 'What is locum work?',
    data: 'Locum work refers to temporary or short-term positions undertaken by professionals, often in the healthcare industry. This includes doctors, nurses, pharmacists, and other healthcare professionals who fill in for absent staff, provide coverage during peak times, or assist in situations requiring additional manpower.',
  },
  {
    title: 'How does the locum placement process work?',
    data: 'The locum placement process typically involves registering with a locum agency or directly with healthcare facilities. Once registered, professionals are matched with available positions based on their skills, preferences, and the needs of the facility. The agency or facility handles the logistics, such as scheduling, accommodation, and compensation.',
  },
  {
    title: 'What are the benefits of working as a locum?',
    data: 'Working as a locum offers flexibility in terms of schedule and location. It allows professionals to gain diverse experience, exposure to different work environments, and often provides higher earning potential. Additionally, locum work can be an ideal option for those seeking a better work-life balance.',
  },
  {
    title: 'What qualifications or experience do I need to work as a locum?',
    data: 'The specific qualifications and experience required for locum work depend on the profession and the healthcare setting. Generally, professionals need to be fully qualified and licensed in their respective fields. Some facilities may also require a minimum amount of post-graduate experience.',
  },
  {
    title: 'How are locum assignments compensated?',
    data: 'Locum compensation varies based on factors such as profession, specialty, location, and the duration of the assignment. Compensation typically includes an hourly or daily rate, and many locum positions offer additional benefits like travel allowances, accommodation, and malpractice insurance.',
  },
  {
    title: 'How can I ensure a smooth transition between locum assignments?',
    data: 'TTo ensure a smooth transition between locum assignments, maintain open communication with the locum agency or facility.',
  },
];
