import React from 'react';

import Page from '../../components/Page/Page';
import LocumJobs from './LocumJobs/LocumJobs';
import Mission from './Mission/Mission';
import WhoWeAre from './WhoWeAre/WhoWeAre';

const AboutUs = () => {
  return (
    <Page title="LocumSpace | About Us">
      <LocumJobs />
      <WhoWeAre />
      <Mission />
    </Page>
  );
};

export default AboutUs;
