import React from 'react';

import styled from '@emotion/styled';

import { PrimaryButton } from '../Button/PrimaryButton';
import { ButtonLink } from '../Link/Link';

interface ICarouselCard {
  title: string;
  description: string;
  link: string;
  className?: string;
}
const CarouselCard: React.FC<ICarouselCard> = ({
  title,
  description,
  link,
  className,
}) => {
  return (
    <Container className={className || ''}>
      <TextArea>
        <Title>{title}</Title>
        <MainContent>{description}</MainContent>
        <ButtonLink to={link}>
          <PrimaryButton className="weight500">More information</PrimaryButton>
        </ButtonLink>
      </TextArea>
    </Container>
  );
};

export default CarouselCard;

// styles
const Container = styled.div`
  display: None;
  border-radius: 0.25rem;
  border: 1px solid var(--grey-grey-4, #f8f8f8);
  box-shadow: 0px 20px 26px 0px rgba(186, 182, 182, 0.16);
  padding: 3.13rem 2.19rem;
  height: 20rem;

  &.showCarouselView {
    background-color: white;
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const Title = styled.h4`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.blackBlack2};

  @media (max-width: 480px) {
    font-size: 1.125rem;
    font-weight: 400;
  }
`;

const MainContent = styled.p`
  font-weight: 400;
`;

const TextArea = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  /* border: 2px solid red; */
`;
