export const missionConfig = [
  {
    title: 'MISSION STATEMENT',
    description:
      'Our mission extends beyond facilitating locum placements. We aim to foster a supportive and collaborative community that ensures provision of safe human resource capacity in healthcare and continuous professional development for health workers, we empower locum professionals to excel in their careers and make a lasting impact on patient outcomes.',
  },
  {
    title: 'VISION STATEMENT',
    description:
      'Our vision is to shape the future of locum practice by ensuring that healthcare workers get credit for working in the freelance space and health facilities are equipped with the best workforce invariably improving the quality of healthcare services in the country.',
  },
  {
    title: 'OUR VALUES',
    description:
      'At the centre of our innovative approach is community, we are keen on fostering the long aged valued relationship among all cadres of healthcare workers, a place where each member is equipped with the right resources to thrive. Fair and equitable pay for all healthcare workers informed by global standards, maintaining and strengthening the quality assurance of licensed healthcare workers practising within  the country.   ',
  },
];
