import React from 'react';

import styled from '@emotion/styled';

import { Accordion, AccordionCell } from '../../components/Accordion/Accordion';
// import MailingList from '../../components/MailingList/MailingList';
import Page from '../../components/Page/Page';
import { faqConfig } from './faqConfig';

const FAQs = () => {
  return (
    <Page title="LocumSpace | FAQs">
      <AccordionContainer>
        <Title>Frequently Asked Questions (FAQs)</Title>
        <Accordion>
          {faqConfig.map((item, index) => (
            <AccordionCell key={index} title={item.title}>
              {item.data}
            </AccordionCell>
          ))}
        </Accordion>
      </AccordionContainer>
      {/* <MailingList /> */}
    </Page>
  );
};

export default FAQs;

// styles
const AccordionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.38rem;
  margin: 6.25rem 9.94rem;
  /* border: 2px solid yellow; */

  @media (max-width: 480px) {
    /* border: 1px solid red; */
    margin: 0;
    padding: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.palette.blackBlackMain};
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;
