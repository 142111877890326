import React from 'react';

// import MailingList from '../../components/MailingList/MailingList';
import Page from '../../components/Page/Page';
import TrustedBy from '../../components/TrustedBy/TrustedBy';
import AboutUs from './AboutUs/AboutUs';
import DoctorReview from './DoctorReview/DoctorReview';
import GetOurApp from './GetOurApp/GetOurApp';
import GettingStarted from './GettingStarted/GettingStarted';
import LocumSpaceGotYouCovered from './LocumSpaceGotYouCovered/LocumSpaceGotYouCovered';
import RecentlyAddedLocum from './RecentlyAddedLocum/RecentlyAddedLocum';
import WhyChooseUs from './WhyChooseUs/WhyChooseUs';

const Home = () => {
  return (
    <Page title="LocumSpace | Home">
      <div>
        <GettingStarted />
        <TrustedBy />
        <AboutUs />
        <LocumSpaceGotYouCovered />
        <WhyChooseUs />
        <RecentlyAddedLocum />
        <DoctorReview />
        {/* <MailingList /> */}
        <GetOurApp />
      </div>
    </Page>
  );
};

export default Home;
